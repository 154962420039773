/** @jsx jsx */
import { jsx } from "theme-ui"
import {useState} from "react"
import { Link,useStaticQuery,graphql } from "gatsby"
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import Theme from "../components/theme"

const ListLink = (props) => (<li><Link to={props.to}>{props.children}</Link></li>)


const Navigation =  (props)=> {
  const data = useStaticQuery(graphql`
    query Menu {
      allGraphCmsMenu(sort: {fields: order, order: ASC}) {
        nodes {
          path
          title
        }
      }
    }
  `)
  const [showMenu, setShowMenu] = useState(false)
  const handleToggleClick = ()=> {
    setShowMenu(!showMenu)
  }

  const listMenuItems = data.allGraphCmsMenu.nodes.map((menuItem, index) => 
    <ListLink key={index} to={menuItem.path}>{menuItem.title}</ListLink>
  )
  return (
    <nav className="site-navigation" sx={navStyle.menu}>
      <button onClick={handleToggleClick} className={"menu-trigger" + (showMenu ? " is-active" : "")}>
        <div className="icon-menu-line"><RiMenu3Line/></div>
        <div className="icon-menu-close"><RiCloseLine/></div>
      </button>
      <ul>
        {listMenuItems}
        <div sx={navStyle.border}></div>
        <div sx={navStyle.theme}><Theme/></div>
      </ul>
    </nav>
  )

}

export default Navigation

const navStyle = {
  menu:{
    "ul":{
      bg: "primary",
    },
  },
  theme:{
    display:["block", "block", "block", "none"],
    p:" 25px 20px 20px",
  },
  border: {
    bg:"borderColor",
    borderTop:"1px solid transparent",
    display:["block", "block", "block", "none"],
  }
}