import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, image, article }) => {
  const { pathname } = useLocation()
  const data  = useStaticQuery(graphql`
    query SEO {
      allGraphCmsMeta {
        nodes {
          key
          value
        }
      }
    }
  `)

  let siteMeta = {}
  for(let node of data.allGraphCmsMeta.nodes){
    siteMeta[node.key] = node.value
  }

  const seo = {
    title: siteMeta.title,
    description: siteMeta.description,
    image: `${siteMeta.siteUrl}${siteMeta.image}`,
    url: `${siteMeta.siteUrl}${pathname}`,
    titleTemplate:siteMeta.titleTemplate,
    twitterUsername:siteMeta.twitterUsername
  }

  return (
    <Helmet title={seo.title} titleTemplate={seo.titleTemplate}>
      <html lang="en-US"/>
      <link rel="alternate" href={seo.url} hreflang="en-us" />
      <link rel="alternate" href={seo.url} hreflang="en" />
      <link rel="alternate" href={seo.url} hreflang="x-default" />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      {seo.url && <meta property="og:url" content={seo.url} />}

      {(article ? true : null) && <meta property="og:type" content="article" />}

      {seo.title && <meta property="og:title" content={seo.title} />}

      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      {seo.image && <meta property="og:image" content={seo.image} />}

      {/* <meta name="twitter:card" content="summary_large_image" />

      {seo.twitterUsername && (
        <meta name="twitter:creator" content={seo.twitterUsername} />
      )}

      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}

      {seo.image && <meta name="twitter:image" content={seo.image} />} */}
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}